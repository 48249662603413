<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].lightgrey}" id="app">
        <div style="height:100%;">
            <v-fade-transition mode="out-in" >
                <router-view/>
            </v-fade-transition>
        </div>
        <div class="snackBars pa-6">
            <v-scroll-x-reverse-transition group leave-absolute>
                <div v-for="(snackbar, index) in snackbars" :key="index">
                    <v-alert border='left' :icon="'$check_s'" dense width="400" type="success" class="mb-0 mt-3">
                        <span v-html="snackbar.message"></span>
                    </v-alert>
                </div>
            </v-scroll-x-reverse-transition>
        </div>
        <div class="snackBars pa-6">
            <v-scroll-x-reverse-transition leave-absolute>
                <div v-if="$store.state.auth.frontVersionBase != $store.state.auth.frontVersionNew">
                    <v-alert border='left' :icon="'$warning_s'" width="600" type="info" class="mb-0 mt-3">
                        <h6 style="color: white; padding-bottom: 0;" v-html="$t('font_version_warning_text')"></h6>
                    </v-alert>
                </div>
            </v-scroll-x-reverse-transition>
        </div>
        <ErrorDialogComponent />
        <ErrorUserDialogComponent />
    </v-app>
</template>

<script>
    import ErrorDialogComponent from '@/components/dialogs/ErrorDialogComponent'
    import ErrorUserDialogComponent from '@/components/dialogs/ErrorUserDialogComponent'
    import { i18n } from "@/i18n";
    import { isMobile } from 'mobile-device-detect';
    export default {
        name: "App",
        components: {
            ErrorDialogComponent,
            ErrorUserDialogComponent
        },
        data() {
            return {
            }
        },
        computed: {
            theme() {
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            },
            favicon: function(){
                return this.$store.state.favicon
            },
            snackbars: function(){
                return this.$store.state.snackbars
            }
        },
        methods: {
            handleIcon() {
                if(this.favicon) {
                    document.getElementById("favicon").href = this.favicon;
                }
            },
        },
        created() {
            this.handleIcon();
            this.$store.dispatch('base/GET_MENU')
            if(isMobile){
                window.location.href = process.env.VUE_APP_MOBILE_URL
            }
        },
        watch: {
            favicon (newFavicon) {
                this.handleIcon();
            },
        },
    }
</script>

<style lang="scss">
    .v-application .headline, .v-application .title  {
        &.h4{
            font-family: Nunito, Helvetica, Arial, sans-serif !important;
        }
        font-family: Nunito, Helvetica, Arial, sans-serif !important;
    }
    .v-application .body-2, .v-application .subtitle-1 {
        &.v-btn {
            font-family: Nunito, Helvetica, Arial, sans-serif !important;
        }
        font-family: Nunito, Helvetica, Arial, sans-serif !important;
    }
    .v-application .text-body-2, .v-application .text-button {
        &.v-toolbar__title {
            font-family: Nunito, Helvetica, Arial, sans-serif !important;
        }
        font-family: Nunito, Helvetica, Arial, sans-serif !important;
    }
    html {
        overflow-y: hidden !important;
        height:100%;
    }
    .snackBars {
        position:absolute;
        bottom:0;
        right:0;
        z-index:2000;
    }
    body {
        height:100%;
    }

    .fa-bars {
        font-size: 20px;
    }
    .list-item {

        margin-right: 10px;
    }
    .list-enter-active, .list-leave-active {
        position:absolute;
        transition: all 0.5s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateX(50px);
    }
    a {
        text-decoration: none;
    }

    .link {
        text-decoration: underline;
        color: var(--v-primary-base);

        a{
            color: var(--v-primary-base) !important;

            &:hover{
                color: var(--v-secondary-base) !important;
            }
        }
        &:hover{
            cursor:pointer;
            color: var(--v-secondary-base)
        }
    }
    .slide-left-enter-active, .slide-left-leave-active {
        transition: all .3s;
        //opacity: 0;
    }
    .slide-left-enter {
        transform:translateX(60%);
        opacity: 0;
    }
    .slide-left-leave-to {
        transform:translateX(-60%);
        opacity: 0;
    }

    .slide-right-enter-active, .slide-right-leave-active {
        transition: all .2s;
        //opacity:0;
    }
    .slide-right-enter {
        transform:translateX(-60%);
        opacity: 0;
    }
    .slide-right-leave-to {
        transform:translateX(60%);
        opacity: 0;
    }
    .v-application .title {
        font-family: Nunito, Helvetica, Arial, sans-serif !important;
    }
    #app {
        font-family: Nunito, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        display: flex;
        flex-direction: row;
        height:100%;
        // .v-application--wrap {
        //     display: flex;
        //     flex-direction: row;
        // }
        .container-menu {
            width:100%;
            text-align: left;
            display: flex;
            flex-direction: row;
        }
        .container-menu-fullscreen {
            width:100%;
            min-height:100%;
            background-color:white;
            .animBlock {
                position: absolute;
                width:100%;
                height:76px;
                background-color:var(--v-primary-base) !important;
            }
            .header-fullscreen {
                position:fixed;
                z-index:1300;
                top:0;
                width:100%;
                padding:20px;
                background-color: var(--v-primary-base) !important;
                color: var(--v-primarytext-base) !important;
            }
        }

        .header-fullscreen {
            position:fixed;
            z-index:1300;
            top:0;
            width:100%;
            padding:20px;
            background-color: var(--v-primary-base) !important;
            color: var(--v-primarytext-base) !important;
        }

        .bg-gradient{
            background: rgb(96,5,255);
            background: -moz-linear-gradient(90deg, rgba(96,5,255,1) 0%, rgba(181,102,255,1) 100%) !important;
            background: -webkit-linear-gradient(90deg, rgba(96,5,255,1) 0%, rgba(181,102,255,1) 100%) !important;
            background: linear-gradient(90deg, rgba(96,5,255,1) 0%, rgba(181,102,255,1) 100%) !important;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6005ff",endColorstr="#b566ff",GradientType=1) !important;
            color:#ffffff;
        }
        .bg-orange{
            background: rgb(255,180,111);
            background: -moz-linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
            background: -webkit-linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
            background: linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb46f",endColorstr="#ff3ebb",GradientType=1);
            color:#ffffff;
        }

        .bg-orange-reverse{
            background: rgb(255,180,111);
            background: -moz-linear-gradient(-60deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 70%, rgba(255,62,187,1) 100%);
            background: -webkit-linear-gradient(-60deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 70%, rgba(255,62,187,1) 100%);
            background: linear-gradient(-60deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 70%, rgba(255,62,187,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb46f",endColorstr="#ff3ebb",GradientType=1);
            color:#ffffff;
        }

        width: 100%;
    }

    html, p, div, .v-alert {
        font-size: 13px;
        line-height: 18px;
        @media (-webkit-device-pixel-ratio: 1.25) {
            font-size: 12px;
             line-height: 16px;
        }
    }
    html {
        font-size:16px;
    }
    h1, .h1 {
        font-size:35px;
        margin-bottom:10px;
        color: #000000 !important;
        &.icon-title {
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
        &.blacked {
            font-size:32px;
            font-weight: 900;
            line-height:normal;
            margin-bottom:0px;
        }
    }

    h2, .h2 {
        font-size:18px;
        min-height:36px;
        line-height: 18px;
        margin-bottom:15px;
        font-weight:800;
        color: #000000 !important;
        text-transform: uppercase;
        display:flex;
        align-items: flex-end;
        &.icon-title {
            justify-content: space-between;
        }
        @media (-webkit-device-pixel-ratio: 1.25) {
            font-size:17px;
            align-items: flex-end;
        }
        span {
            display:flex;
            align-items:center;
        }
    }
    h3, .h3 {
        font-size:18px;
        color: var(--v-darkgrey-base) !important;
        text-transform: uppercase;
        &.icon-title {
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
         @media (-webkit-device-pixel-ratio: 1.25) {
            font-size:16px;
        }
    }
    h4, .h4 {
        font-size:16px;
        padding-bottom:8px;
        text-transform: uppercase;
        color: #000;
        font-family: Nunito !important;
        font-style: normal !important;
        font-weight: 900 !important;
        border-bottom: 1px solid #000;
        &.icon-title {
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
        &.menu {
            border-bottom: 1px solid #FFF;
        }
    }
    h5, .h5 {
        font-size:20px;
        padding-bottom:8px;
        color: #000;
        font-family: Nunito !important;
        font-style: normal !important;
        font-weight: 700 !important;
        &.icon-title {
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
        
    }
    h6 {
        font-size:19px;
        padding-bottom:8px;
        color: #000;
        font-family: Nunito !important;
        font-style: normal !important;
        font-weight: 700 !important;
    }
    .h7 {
        font-size:19px;
        padding-bottom:8px;
        color: #000;
        font-family: Nunito !important;
        font-style: normal !important;
        font-weight: 800 !important;
    }
    p.subtitle {
        margin-bottom:0 !important;
        font-size:15px;
        font-weight:bold;
    }
    .square {
        min-width:unset !important;
        padding: 0 !important;
        width:36px !important;
        @media (-webkit-device-pixel-ratio: 1.25) {
            width:30px !important;
            height:30px !important;
        }
        &.v-size--small{
            width:28px !important;
            @media (-webkit-device-pixel-ratio: 1.25) {
                width:25px
            }
        }
        &.v-size--x-small {
            width:20px !important;
        }
    }
    .v-btn{
        text-transform: inherit !important;

        .v-btn__content{
            letter-spacing: 0px;
            font-size:16px;
            @media (-webkit-device-pixel-ratio: 1.25) {
                font-size:14px;
            }
        }
    }

    .v-btn:not(.v-btn--round).v-size--x-large{
        height:45px !important;
        font-weight: 600;
    }

    .v-sheet.v-card:not(.v-sheet--outlined),
    .v-expansion-panel::before{
        -webkit-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        -moz-box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
        box-shadow: 3px 3px 10px -1px rgba(0,0,0,0.11) !important;
    }
    .v-sheet.v-card:not(.v-sheet--outlined).no-shadow {
        -webkit-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        -moz-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
    }
    .v-expansion-panel.no-shadow::before {
        -webkit-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        -moz-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
    }
    .v-stepper__header.no-shadow {
        -webkit-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        -moz-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
    }
    .v-stepper.no-shadow {
        -webkit-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        -moz-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
        box-shadow: 2px 2px 6px 0px rgba(235,235,235,0) !important;
    }
    .v-dialog {
        .v-toolbar {
            max-height: 64px;
        }
    }
    .datatable-container {
        max-width:1860px !important;
    }

    .listTitle {
        color:#000000;
        font-size:14px;
        font-weight: 800;
        border-bottom:1px solid rgba(0,0,0,0.2);
    }
    
    .white--tooltip {
        opacity: 1 !important;
        background-color: white;
        color: black;
        box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.11) !important;
    }

    .h-100 {
        height: 100%;
    }
</style>
