import GenericDataService from '@/services/GenericDataService'
import vuetify from '@/plugins/vuetify'
import {i18n, loadLanguageAsync} from '@/i18n'

const state = () => ({
    idToken:"",
    accessToken:"",
    user: {}, // currentUser OU loggedAs
    currentUser: {}, // Toujours le user principal
    loggedAs: null, // Toujour le user en tant que
    currentAccountId: 0,
    currentRoleId: 0,
    menuUser: [],
    openTodoDrawer: false,
    openNotifDrawer: false,
    openTodoActionDrawer: false,
    openOperationDrawer: false,
    notifs:[],
    todos: [],
    todosFilterUsers: [],
    todosFilterTypes: [],
    todoForDrawer:{},
    operationForDrawer:{},
    excerptForDrawer: {},
    avatarKey: 0,
    openNeedHelpDialog: false,
    openCallScriptDrawer: false,
    openCallScriptFullDrawer: false,
    openDiabolocomFullDrawer: true,
    todoIdForCallScriptDrawer: 0,
    rpIdForCallScriptDrawer: 0,
    templateForCallScriptDrawer: {},
    frontVersionBase: 0,
    frontVersionNew: 0,
})

const mutations = {
    setAuth(state, payload){
        state.idToken = payload.idToken
        state.accessToken = payload.accessToken

        let name = "account_id=";
        let ca = document.cookie.split(';');
        let account_id = 0
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                account_id = c.substring(name.length, c.length);
            }
        }
        if(account_id != 0){
            state.currentAccountId = account_id
        }

        name = "role_id=";
        ca = document.cookie.split(';');
        let role_id = 0
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                role_id = c.substring(name.length, c.length);
            }
        }
        if(role_id != 0){
            state.currentRoleId = role_id
        }

    },
    setCurrentAccountId(state, accountId){
        state.currentAccountId = accountId
    },
    setCurrentRoleId(state, roleId){
        state.currentRoleId = roleId
    },
    setCurrentUser(state, currentUser){
        state.currentUser = currentUser.data.data
        state.currentAccountId = currentUser.data.data.accountParameters.id
        state.currentRoleId = currentUser.data.data.roleParameters.id
        document.title = state.currentUser.accountParameters.name
        state.menuUser = [
            {
                "title" : "my-profil",
                "url" : "/administration/users/" + state.currentUser.profile.id
            },
            {
                "title" : "log-as",
                "url" : "/administration/users",
                "right": "user/logas"
            },
            {
                "title" : "set-indisponibility",
                "right": "user/advanced"
            },
            {
                "title" : "need-help",
            },
            {
                "title" : "log-out",
                "url": "/logout"
            },
        ];
        
        state.loggedAs = currentUser.data.data.loggedAs
        state.user = currentUser.data.data.loggedAs ? state.loggedAs : state.currentUser.profile;
        // Définifion de la langue
        if(currentUser.data.data.parameters.lang){
            loadLanguageAsync(currentUser.data.data.parameters.lang, currentUser.data.data.trads)
        } else {
            loadLanguageAsync(currentUser.data.data.profile.lang.id, currentUser.data.data.trads)
        }
        
    },
    setColorForUser(state, payload) {
        state.currentUser.parameters.theme = payload
    },
    setLoggedAs(state, userData) {
        state.loggedAs = userData;
        state.user = userData ? state.loggedAs : state.currentUser;
    },
    setOpenTodoDrawer(state, value) {
        state.openTodoDrawer = value;
    },
    setOpenNotifDrawer(state, value) { 
        state.openNotifDrawer = value;
    },
    setOpenTodoActionDrawer(state, value) { 
        state.openTodoActionDrawer = value;
    },
    setTodoForDrawer(state, value){
        state.todoForDrawer = value
    },
    setOpenOperationDrawer(state, value) { 
        state.openOperationDrawer = value;
    },
    setOperationForDrawer(state, value){
        state.operationForDrawer = value
    },
    setExcerptForDrawer(state, value){
        state.excerptForDrawer = value
    },
    setUserTodos(state, value){
        state.todos = value.todos;
        state.todosFilterUsers = value.todosFilterUsers;
        state.todosFilterTypes = value.todosFilterTypes;
    },
    setNotif(state, value){
        state.notifs = value
    },
    changeAvatarKey(state){
        state.avatarKey++
    },
    setOpenNeedHelpDialog(state, value){
        state.openNeedHelpDialog = value
    },
    setOpenCallScriptDrawer(state, value) {
        state.openCallScriptDrawer = value;
    },
    setOpenCallScriptFullDrawer(state, value) {
        state.openCallScriptFullDrawer = value;
    },
    setOpenDiabolocomFullDrawer(state, value) {
        state.openDiabolocomFullDrawer = value;
    },
    setTodoIdForCallScriptDrawer(state, value) {
        state.todoIdForCallScriptDrawer = value;
    },
    setRpIdForCallScriptDrawer(state, value) {
        state.rpIdForCallScriptDrawer = value;
    },
    setTemplateForCallScriptDrawer(state, value) {
        state.templateForCallScriptDrawer = value;
    },
    setFrontVersionBase(state, value) {
        state.frontVersionBase = value;
    },
    setFrontVersionNew(state, value) {
        state.frontVersionNew = value;
    },
}


const actions = {
    async GET_CURRENT_USER({commit, dispatch}) {
        await GenericDataService.getData("myprofile/get").then((response) => {
            commit("setCurrentUser", response);
            commit('setLogos', response.data.data.parameters.theme, { root: true });
            dispatch('base/GET_MENU', null, { root:true });
        })
    },
    SET_CURRENT_ACCOUNT_ID({ commit }, value){
        commit("setCurrentAccountId", value)
    },
    SET_CURRENT_ROLE_ID({ commit }, value){
        commit("setCurrentRoleId", value)
    },
    CHANGE_AVATAR_KEY({ commit }) {
        commit("changeAvatarKey")
    },
    SET_LOGGED_AS({ commit }, userData) {
        commit("setLoggedAs", userData)
    },
    SET_OPEN_TODO_DRAWER({ commit }, value) {
        commit("setOpenTodoDrawer", value)
    },
    SET_OPEN_TODO_ACTION_DRAWER({ commit }, payload) {
        commit("setOpenTodoActionDrawer", payload.value)
        commit("setTodoForDrawer", payload.todo)
        commit("setExcerptForDrawer", payload.excerpt)
    },
    SET_OPEN_OPERATION_DRAWER({ commit }, payload) {
        commit("setOpenOperationDrawer", payload.value)
        commit("setOperationForDrawer", payload.operation)
        commit("setExcerptForDrawer", payload.excerpt)
    },
    async SET_OPEN_NOTIF_DRAWER({ commit }, value) {
        if(value){
            await GenericDataService.getData('notif/list').then((response)=>{
                commit("setNotif", response.data.data);
                commit("setOpenNotifDrawer", value)
            })
        } else {
            commit("setOpenNotifDrawer", value)
        }
    },
    SET_OPEN_NEED_HELP_DIALOG({commit}, value){
        commit('setOpenNeedHelpDialog', value)
    },
    SET_OPEN_CALL_SCRIPT_DRAWER({ commit }, payload) {
        commit("setOpenCallScriptDrawer", payload.value);
        commit("setOpenCallScriptFullDrawer", payload.value);
        commit("setTodoIdForCallScriptDrawer", payload.todoId);
        commit("setRpIdForCallScriptDrawer", payload.rpId);
    },
    SET_OPEN_CALL_SCRIPT_DRAWER_WITHOUT_ID({ commit }, value) {
        commit("setOpenCallScriptDrawer", value);
        commit("setOpenCallScriptFullDrawer", value);
    },
    SET_OPEN_CALL_SCRIPT_FULL_DRAWER({ commit }, value) {
        commit("setOpenCallScriptFullDrawer", value);
    },
    SET_TEMPLATE_FOR_CALL_SCRIPT_DRAWER({ commit }, value) {
        commit("setTemplateForCallScriptDrawer", value);
    },
    SET_FRONT_VERSION_BASE({ commit }, value) {
        commit("setFrontVersionBase", value);
    },
    SET_FRONT_VERSION_NEW({ commit }, value) {
        commit("setFrontVersionNew", value);
    },
    SET_OPEN_DIABOLOCOM_FULL_DRAWER({ commit }, value) {
        commit("setOpenDiabolocomFullDrawer", value);
    },
}

const getters = {

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
